import React, { useState } from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
import { Fade } from "react-awesome-reveal";

import Layout from "../../components/layout";
import locales from "../../constants";
import projects from "../../data/portfolio.json";

const Portfolio = ({ pageContext: { slug } }) => {
  const [portfolioType, setPortfolioType] = useState("Typ projektu");
  const [category, setCategory] = useState(null);

  const categories = [
    {
      type: "Typ projektu",
      typeEN: "Project Type",
      list: [
        "Strony internetowe",
        "E-commerce",
        "Branding",
        "Kampanie",
        "Case Study",
        "Konkurs",
      ],
    },
    {
      type: "Kategoria/Branża",
      typeEN: "Category/Industry",
      list: [
        "Eventy",
        "FMCG",
        "Gaming",
        "Inwestycje",
        "Motoryzacja",
        "Nieruchomości",
        "Strony firmowe",
        "Pozostałe",
      ],
    },
  ];

  return (
    <Layout
      seo={{
        title: "Projects",
        href: slug,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/",
      }}
    >
      <section className="container-fluid page_portfolio">
        <h1>Projects</h1>
        <div className="page_portfolio__switch">
          {categories.map((item, index) => (
            <button
              key={index}
              className={portfolioType === item.type ? "current" : ""}
              onClick={() => {
                setPortfolioType(item.type);
                setCategory(null);
              }}
            >
              {item.typeEN}
            </button>
          ))}
        </div>

        <div className="page_portfolio__nav">
          <button
            className={category === null ? "current" : ""}
            onClick={() => setCategory(null)}
          >
            Show all
          </button>
          {categories
            .filter((item) => item.type === portfolioType)
            .map((item) =>
              item.list.map((node) => (
                <button
                  className={category === node ? "current" : ""}
                  key={node}
                  onClick={() => setCategory(node)}
                >
                  {node === "Strony internetowe"
                    ? "Web development"
                    : node === "E-commerce"
                    ? "E-Commerce"
                    : node === "Kampanie"
                    ? "Lead Campaign"
                    : node === "Konkurs"
                    ? "Contest"
                    : node === "Eventy"
                    ? "Events"
                    : node === "Inwestycje"
                    ? "Investments"
                    : node === "Motoryzacja"
                    ? "Automotive"
                    : node === "Nieruchomości"
                    ? "Real Estates"
                    : node === "Strony firmowe"
                    ? "Business Sites"
                    : node === "Pozostałe"
                    ? "Other"
                    : node}
                </button>
              ))
            )}
        </div>

        <div className="row">
          {projects
            .filter((item) =>
              category === null ? item : item?.filtration?.includes(category)
            )
            .filter((item) => item.hasTranslation === true)
            .map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <Fade triggerOnce="true">
                    <LazyLoad>
                      <Link
                        to={`/en/projects${item.url.en || item.url}`}
                        className="portfolio_tile"
                      >
                        <h3>
                          {item.name}
                          <span>
                            {Object.keys(locales.en.portfolio_navs).map(
                              (key) => {
                                if (key === item.var) {
                                  return locales.en.portfolio_navs[key];
                                } else {
                                  return null;
                                }
                              }
                            )}
                          </span>
                        </h3>
                        <div
                          className="portfolio_tile-back"
                          style={{
                            backgroundImage: `url(${require(`../../assets/img/portfolio_grid/${item.var}_back.png`)})`,
                          }}
                        />
                        <img
                          className="img-fluid"
                          src={require(`../../assets/img/portfolio_grid/${item.var}_front.png`)}
                          alt=""
                        />
                      </Link>
                    </LazyLoad>
                  </Fade>
                </div>
              );
            })}
        </div>
      </section>
    </Layout>
  );
};

export default Portfolio;
